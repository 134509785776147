import React from 'react';
import './App.css';
import singularImage from './singular.png';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={singularImage} alt="Singularity" />
                <p>
                    SinguLayerty API Services
                </p>
            </header>
        </div>
    );
}

export default App;
